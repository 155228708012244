import { ReactNode } from 'react'
import { PlacesType } from 'react-tooltip'

import { Tooltip } from './Tooltip'

interface Props {
    tooltipContent: string | ReactNode
    makeTransparent ?: boolean
    children: ReactNode
    placement?: PlacesType
}

export const TooltipButtonWrapper = ({ tooltipContent, children, placement='top', makeTransparent }: Props) => {
    return (
        <Tooltip content={tooltipContent} placement={placement} makeTransparent={!!makeTransparent}>
            <div className="flex items-center hover:bg-gray-100 rounded-md cursor-pointer p-2">{children}</div>
        </Tooltip>
    )
}
