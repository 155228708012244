import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { storage } from '../../../lib/storage'
import { updateDictationReducer } from '../../../redux/reducers/visits-slice'
import { visitsService } from '../../../services/http/visits.service'

type HandleStatus = (status: 'saved' | 'unsaved' | 'saving') => void

export const useDictationFunctions = (dictationId: string, dictationContent: string, handleStatus: HandleStatus) => {
    const [autosaveDictation, setAutosaveDictation] = useState(false)
    const [networkIssue, setNetworkIssue] = useState(false)

    const dispatch = useDispatch()

    const handleBeforeUnload = useCallback(() => {
        // Check dictation in localstorage
        const dictation = storage.getDictation(dictationId)

        // If saved then remove dictation
        if (dictation?.status === 'saved') storage.clearSavedDictations()
    }, [dictationId])

    const handleContentChange = useCallback(
        (content: string) => {
            // Change content in redux state
            dispatch(updateDictationReducer({ _id: dictationId, dictation: content }))

            // Save to local storage
            storage.storeDictation(dictationId, content)

            // Update status
            handleStatus('unsaved')

            // Call backend to update dictation content
            setAutosaveDictation(true)
        },
        [dictationId, dispatch, handleStatus]
    )

    useEffect(() => {
        // == Handle autoSave == //
        if (!autosaveDictation) return
        const interval = setInterval(
            async () => {
                try {
                    handleStatus('saving')
                    setAutosaveDictation(false)
                    setNetworkIssue(false)
                    await visitsService.updateVisit(dictationId, { dictation: dictationContent })
                    storage.updateDictationStatus(dictationId, 'saved')
                    handleStatus('saved')
                } catch (error) {
                    handleStatus('unsaved')
                    storage.updateDictationStatus(dictationId, 'unsaved')
                    setAutosaveDictation(true)
                    setNetworkIssue(true)
                }
            },
            !networkIssue ? 1000 : 10000
        )

        return () => clearInterval(interval)
    }, [autosaveDictation, dictationId, dictationContent, networkIssue, handleStatus])

    useEffect(() => {
        // == Handle storage before unload == //
        // Check status in local storage
        const dictation = storage.getDictation(dictationId)

        // Save unsaved content
        if (dictation?.status === 'unsaved') {
            handleContentChange(dictation.content)
        }

        // If status === 'saved' remove from local storage
        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            // Clean up the event listener
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [dispatch, handleBeforeUnload, dictationId, handleContentChange])

    return {
        handleContentChange,
        handleBeforeUnload
    }
}
