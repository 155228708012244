import classNames from 'classnames'
import { ReactNode, useMemo } from 'react'
import { PlacesType, Tooltip as TooltipLib } from 'react-tooltip'
import styled from 'styled-components'

interface Props {
    className?: string
    makeTransparent ?: boolean
    placement: PlacesType
    content: string | ReactNode
    children: ReactNode
}

const Styles = styled.div`
    .transparentTooltip {
        padding: 0 !important;
        background-color: transparent !important;
    }
`

export const Tooltip = ({ className, makeTransparent = false, placement, content, children }: Props) => {
    const id = useMemo(() => makeId(), [])

    return (
        <>
            <Styles id={id} data-tooltip-place={placement || 'right'} data-tooltip-position-strategy="fixed" className={classNames(className)}>
                {children}

                <TooltipLib
                    arrowColor={makeTransparent ? 'transparent' : 'inherit'}
                    className={makeTransparent ? 'transparentTooltip' : ''}
                    anchorSelect={`#${id}`}
                >
                    {content}
                </TooltipLib>
            </Styles>
        </>
    )
}

const makeId = (length: number = 6): string => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const charLength = chars.length
    let result = ''
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * charLength))
    }
    return result
}
