enum KEY {
    EMR_TOKEN = 'fl_emr_token',
    ACCESS_TOKEN = 'fl_token',
    REFRESH_TOKEN = 'fl_refresh',
    USERNAME = 'fl_username',
    PLAN = 'fl_plan',
    MICROPHONE_DEVICE_ID = 'fl_mic_device_id',
    EMR_CONTEXT_EFORMS = 'fl_emr_context_eforms'
}

type Session = {
    accessToken: string
    refreshToken: string
    username: string
}

interface Dictation {
    content: string
    status: 'saved' | 'unsaved'
}

export const storage = (() => {
    const storeAccessToken = (session: Session) => {
        localStorage.setItem(KEY.ACCESS_TOKEN, session.accessToken)
        localStorage.setItem(KEY.REFRESH_TOKEN, session.refreshToken)
        localStorage.setItem(KEY.USERNAME, session.username)
    }

    const storeDictation = (id: string, content: string) => {
        let dictations: { [key: string]: Dictation } = {}
        const dictationsDict = localStorage.getItem('dictations')
        if (dictationsDict && dictationsDict.length > 0) dictations = JSON.parse(dictationsDict)

        const selectedDictation: Dictation = dictations[id] || { id: id }

        selectedDictation.content = content
        selectedDictation.status = 'unsaved'

        dictations[id] = selectedDictation

        localStorage.setItem('dictations', JSON.stringify(dictations))
    }
    const clearSavedDictations = (dictationId?: string) => {
        let dictations: { [key: string]: Dictation } = {}
        const dictationsDict = localStorage.getItem('dictations')
        if (dictationsDict && dictationsDict.length > 0) dictations = JSON.parse(dictationsDict)
        if (dictationId) {
            delete dictations[dictationId]
        } else {
            for (const dictationID in dictations) {
                if (dictations[dictationID].status === 'saved') {
                    delete dictations[dictationID]
                }
            }
        }

        localStorage.setItem('dictations', JSON.stringify(dictations))
    }

    const updateDictationStatus = (id: string, status: 'saved' | 'unsaved') => {
        let dictations: { [key: string]: Dictation } = {}
        const dictationsDict = localStorage.getItem('dictations')
        if (dictationsDict && dictationsDict.length > 0) dictations = JSON.parse(dictationsDict)

        const selectedDictation: Dictation = dictations[id] || { id: id }

        selectedDictation.status = status

        dictations[id] = selectedDictation

        localStorage.setItem('dictations', JSON.stringify(dictations))
    }

    const storeEmrToken = (token: string) => {
        localStorage.setItem(KEY.EMR_TOKEN, token)
    }

    const storePlan = (plan: string) => {
        localStorage.setItem(KEY.PLAN, plan)
    }

    const storeMicrophoneDeviceId = (deviceId: string) => {
        localStorage.setItem(KEY.MICROPHONE_DEVICE_ID, deviceId)
    }

    const storeEmrContextEForms = (eforms: any[]) => {
        localStorage.setItem(KEY.EMR_CONTEXT_EFORMS, JSON.stringify(eforms))
    }

    const getDictation = (id: string): Dictation | null => {
        let dictations: { [key: string]: Dictation } = {}
        const dictationsDict = localStorage.getItem('dictations')
        if (dictationsDict && dictationsDict.length > 0) dictations = JSON.parse(dictationsDict)

        const selectedDictation: Dictation | null = dictations?.[id] || null

        return selectedDictation
    }

    const getAccessToken = (): string | null => localStorage.getItem(KEY.ACCESS_TOKEN)

    const getEmrToken = (): string | null => localStorage.getItem(KEY.EMR_TOKEN)

    const getRefreshToken = (): string | null => localStorage.getItem(KEY.REFRESH_TOKEN)

    const getUsername = (): string | null => localStorage.getItem(KEY.USERNAME)

    const getPlan = (): string | null => localStorage.getItem(KEY.PLAN)

    const getMicrophoneDeviceId = (): string | null => localStorage.getItem(KEY.MICROPHONE_DEVICE_ID)

    const getEmrContextEForms = (): any[] => {
        const eforms = localStorage.getItem(KEY.EMR_CONTEXT_EFORMS)
        return eforms ? JSON.parse(eforms) : []
    }

    const clearAccessToken = () => {
        localStorage.removeItem(KEY.ACCESS_TOKEN)
        localStorage.removeItem(KEY.REFRESH_TOKEN)
        localStorage.removeItem(KEY.USERNAME)
    }

    const clearEmrToken = () => localStorage.removeItem(KEY.EMR_TOKEN)

    const clearPlan = () => localStorage.removeItem(KEY.PLAN)

    const clearMicrophoneDeviceId = () => localStorage.removeItem(KEY.MICROPHONE_DEVICE_ID)

    return {
        storeAccessToken,
        storeEmrToken,
        storePlan,
        storeMicrophoneDeviceId,
        storeEmrContextEForms,
        getAccessToken,
        getRefreshToken,
        getUsername,
        getPlan,
        getMicrophoneDeviceId,
        getEmrToken,
        getEmrContextEForms,
        clearAccessToken,
        clearEmrToken,
        clearPlan,
        clearMicrophoneDeviceId,
        storeDictation,
        getDictation,
        updateDictationStatus,
        clearSavedDictations
    }
})()
