import 'react-quill/dist/quill.snow.css'
import './styles.scss'

import { forwardRef, MutableRefObject } from 'react'
import ReactQuill from 'react-quill'

import { useQuillEditor } from '../../../VisitDictationShared/hooks/useQuillEditor'
import { useDictationFunctions } from '../../hooks/useDictationFunctions'
import { quillModules } from './quilljs'

interface Props {
    id: string
    className?: string
    quillRef: MutableRefObject<ReactQuill | null>
    dictationContent: string
    handleStatus: (status: 'saved' | 'unsaved' | 'saving') => void
}

// eslint-disable-next-line react/display-name
export const QuillEditor = forwardRef(({ quillRef, dictationContent, id: dictationId, handleStatus }: Props) => {

    useQuillEditor({ quillRef })
    const { handleContentChange } = useDictationFunctions(dictationId, dictationContent, handleStatus)

    return (
        <>
            <ReactQuill
                ref={quillRef}
                defaultValue={dictationContent}
                value={dictationContent}
                onChange={handleContentChange}
                preserveWhitespace={true}
                theme="snow"
                modules={quillModules}
                
            />
        </>
    )
})
