import { ClipboardIcon, PrinterIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'

import PDFIcon from '../../UI/Icons/PDFIcon'
import { Loader } from '../../UI/Loader'
import { TooltipButtonWrapper } from '../../UI/TooltipButtonWrapper'
import { CommandsTable } from './CommandsTable'
import { CopyDictation } from './CopyDictation'
import { DownloadDictation } from './DownloadDictation'

interface Props {
    dictationContent: string
    dictationId: string
}

export const DictationMenu = ({ dictationContent, dictationId }: Props) => {
    return (
        <div className="flex items-center ml-9 text-gray-500">
            <TooltipButtonWrapper tooltipContent={<CommandsTable />} placement="top-start" makeTransparent={true}>
                <QuestionMarkCircleIcon width={21} />
            </TooltipButtonWrapper>
            <CopyDictation
                dictationContent={dictationContent}
                childNode={() => (
                    <TooltipButtonWrapper tooltipContent="Copy" placement="top">
                        <ClipboardIcon width={21} />
                    </TooltipButtonWrapper>
                )}
            />
            <DownloadDictation
                dictationId={dictationId}
                print={true}
                childNode={(isLoading) => (
                    <TooltipButtonWrapper tooltipContent="Print" placement="top">
                        {isLoading ? <Loader /> : <PrinterIcon width={21} />}
                    </TooltipButtonWrapper>
                )}
            />
            <DownloadDictation
                dictationId={dictationId}
                childNode={(isLoading) => (
                    <TooltipButtonWrapper tooltipContent="Download" placement="top">
                        {isLoading ? <Loader /> : <PDFIcon />}
                    </TooltipButtonWrapper>
                )}
            />
        </div>
    )
}
