import { ShowSidebarButton } from '../UI/AppBar/ShowSidebarButton'
import { CreateVisit } from '../UI/CreateVisit'
import { VisitDictationNavbar } from '../UI/VisitDictationNavbar'
import { MicrophoneSettings } from '../VisitDictationShared/Components/MicrophoneSettings'
import { StartRecordButton } from '../VisitDictationShared/Components/StartRecordButton'
import { CommandsTable } from './components/CommandsTable'

export const WelcomeState = () => {
    return (
        <>
            <div className="bg-white flex flex-col items-center justify-center h-full">
                <ShowSidebarButton />
                <VisitDictationNavbar />
                <div className="flex-1 h-full flex flex-col items-center justify-center text-center">
                    <CreateVisit childNode={(isLoading) => <StartRecordButton isLoading={isLoading} />} type="dictation" startRecordingOnLoad={true} />
                    <div className="mt-2 mb-4 sm:mt-4 sm:mb-6 text-lg font-semibold text-gray-800">Start new Dictation</div>
                    <MicrophoneSettings />

                    <CommandsTable />
                </div>
            </div>
        </>
    )
}
