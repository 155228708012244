import classNames from 'classnames'
import { ReactNode, useCallback } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useHttp } from '../../../hooks/useHttp'
import { visitsService } from '../../../services/http/visits.service'



interface Props {
    dictationId: string
    className?: string
    print?: boolean
    childNode(isLoading: boolean): ReactNode
}

const Styles = styled.div`
    display: inline-block;
`

export const DownloadDictation = ({ className, dictationId, print, childNode }: Props) => {
    const { downloadReq, isLoading } = useHttpReq()
    

    const onClick = useCallback(
        async (event: any) => {
            event.stopPropagation()

            try {
                const blob = await downloadReq(dictationId)
                const url = window.URL.createObjectURL(blob)

                if (print) {
                    window.open(url, '_blank')
                } else {
                    const anchorEl = document.createElement('a')
                    anchorEl.href = url
                    anchorEl.download = `dictation_${dictationId}.pdf`
                    document.body.appendChild(anchorEl)
                    anchorEl.click()
                    setTimeout(() => {
                        window.URL.revokeObjectURL(url)
                        document.body.removeChild(anchorEl)
                    }, 100)
                }
                toast.success(`${print ? 'Printed' : 'Downloaded'} dictation`)
            } catch (error) {
                toast.error(`Failed to ${print ? 'print' : 'download'} dictation`)
            }
        },

        [dictationId, print, downloadReq]
    )

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(isLoading)}
        </Styles>
    )
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const downloadReq = useCallback(async (id: string): Promise<File> => sendRequest(visitsService.downloadDictation.bind({}, id)), [sendRequest])

    return {
        isLoading,
        downloadReq
    }
}
