import { DictationStartPauseButton } from '../../VisitDictationShared/Components/PlayPauseButton'
import { DictationMenu } from './DictationMenu'

interface Props {
    dictationContent: string
    dictationId: string
}

export const ControllerBar = ({dictationContent, dictationId}:Props) => {
    return (
        <div className="flex py-2 items-center justify-start w-full ">
            <DictationStartPauseButton />
            <DictationMenu dictationId={dictationId} dictationContent={dictationContent}/>
        </div>
    )
}
