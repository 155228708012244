import { DICTATION_COMMANDS } from '../../../constants'

export const CommandsTable = () => {
    return (
        <div className="w-full max-w-md p-2 sm:p-0">
            <div className="bg-gray-50 p-3 rounded-lg border border-gray-200 max-h-56 sm:max-h-60 overflow-y-auto">
                <table className="table-auto">
                    <thead>
                        <tr>
                            <th className="px-1 py-2 border-b text-gray-700 text-start font-semibold border-gray-200">Command</th>
                            <th className="px-1 py-2 border-b text-gray-700 font-semibold text-start border-gray-200">Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        {DICTATION_COMMANDS.map((command, index) => (
                            <tr className={`${index < DICTATION_COMMANDS.length - 1 && 'border-b border-gray-200'}`} key={index}>
                                <td className="px-1 py-2 text-gray-700 text-start">{command.command}</td>
                                <td className="px-1 py-2 text-gray-700 text-start">{command.result}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
