import { AxiosResponse } from 'axios'

import { VISITS_PAGINATION_LIMIT } from '../../constants'
import { storage } from '../../lib/storage'
import { Visit, VisitType } from '../models/Visit.model'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getVisits = (userId: string, index: number): Promise<Visit[]> => {
    const limit = VISITS_PAGINATION_LIMIT
    const url = `${baseUrl}/visits/paged?userId=${userId}&index=${index}&limit=${limit}`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                const visits =
                    response.data?.visits?.map((obj: any) =>
                        Visit.deserialize(obj)
                    ) || []
                resolve(visits)
            })
            .catch((error) => reject(error.response?.data || {}))
    })
}

const createVisit = (
    userId: string,
    templateId: string | undefined,
    customTemplateId: string | undefined,
    type: VisitType
): Promise<Visit> => {
    const url = `${baseUrl}/visits?userId=${userId}`
    const payload = {
        templateId,
        customTemplateId,
        type
    }
    return new Promise((resolve, reject) => {
        authAxios
            .post(url, payload)
            .then((response: AxiosResponse) => {
                if (response.data.visit) {
                    const visit = Visit.deserialize(response.data?.visit)
                    resolve(visit)
                } else {
                    reject('Failed to create visit')
                }
            })
            .catch((error) => reject(error.response?.data || {}))
    })
}

const deleteVisit = (id: string): Promise<void> => {
    const url = `${baseUrl}/visits?id=${id}`
    return authAxios.delete(url)
}

const updateVisit = (id: string, payload: {title ?:string, dictation?: string}): Promise<void> => {
    const url = `${baseUrl}/visits?id=${id}`
    return authAxios.put(url, payload)
}

const deleteVisits = (ids: string[]): Promise<void> => {
    const url = `${baseUrl}/visits/delete`
    const payload = { ids }
    return authAxios.post(url, payload)
}

const downloadDictation = (id: string): Promise<Blob> => {
    let timezone = 'America/Toronto'
    try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (error) {
        console.error(error)
    }
    const url = `${baseUrl}/visits/${id}/downloadDictation?timezone=${timezone}`
    const token = storage.getEmrToken() || storage.getAccessToken()

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        xhr.send()
        xhr.responseType = 'arraybuffer'
        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], {
                    type: 'application/pdf',
                })
                resolve(blob)
            } else {
                reject()
            }
        }
    })
}

export const visitsService = {
    getVisits,
    createVisit,
    deleteVisit,
    deleteVisits,
    updateVisit,
    downloadDictation
}
