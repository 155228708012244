import moment from 'moment'

import {
    MOMENT_DATE_FORMAT,
    MOMENT_TIMESECONDS_FORMAT,
    TRANSCRIPTION_MIN_LENGTH,
} from '../../constants'

export class Visit {
    _id!: string
    owner!: string
    title!: string
    duration!: number
    state!: VisitStateType
    templateId?: string
    customTemplateId?: string
    transcripts!: (Transcript | TranscriptPartial)[]
    dictation!: string
    createdAt!: Date
    updatedAt!: Date
    endedAt!: Date
    type!: VisitType

    static deserialize(obj: any): Visit {
        const model: Visit = Object.assign({}, obj)
        model.transcripts =
            obj.transcripts?.map((_obj: any) => Transcript.deserialize(_obj)) ||
            []
        model.createdAt = new Date(obj.createdAt)
        model.updatedAt = new Date(obj.updatedAt)
        model.endedAt = Visit.getEndedAt(model)
        return model
    }

    static filterByTitle(searchTerm: string, visits: Visit[]): Visit[] {
        if (!searchTerm.length) {
            return visits
        }
        searchTerm = searchTerm.toLowerCase()
        const filtered = [...visits].filter((i) =>
            i.title.toLowerCase().includes(searchTerm)
        )
        return filtered
    }

    static getState(state: VisitStateType): string {
        return visitStateRec[state]
    }

    static getStateDescription(state: VisitStateType): string {
        return visitStateDescRec[state]
    }

    static getDurationNote(visit: Visit): string {
        const startTime = moment(visit.createdAt).format(
            MOMENT_TIMESECONDS_FORMAT
        )
        const endTime = moment(visit.endedAt).format(MOMENT_TIMESECONDS_FORMAT)

        const hours = Math.floor(visit.duration / 3600)
        const minutes = Math.floor((visit.duration % 3600) / 60)
        const remainingSeconds = visit.duration % 60

        const parts = []
        if (hours > 0) parts.push(`${hours}hr`)
        if (minutes > 0) parts.push(`${minutes}min`)
        if (remainingSeconds > 0) parts.push(`${remainingSeconds}sec`)
        if (!parts.length) parts.push('-')
        const duration = parts.join(' ')

        return `\nStart Time: ${startTime}\nEnd Time: ${endTime}\nDuration: ${duration}`
    }

    static getEndedAt(visit: Visit): Date {
        const endedAt = moment(visit.createdAt).add(visit.duration, 'seconds')
        return endedAt.toDate()
    }

    static canRecord(state: VisitStateType): boolean {
        return state === 'new' || state === 'transcribing'
    }

    static canFinishRecording(visit: Visit): boolean {
        if (!visit.transcripts?.length) {
            return false
        }
        return (
            visit.transcripts?.map((i) => i.content)?.join('')?.length >=
            TRANSCRIPTION_MIN_LENGTH
        )
    }

    static canRegenerate(visit: Visit): boolean {
        return visit.transcripts.length > 0 && visit.state === 'completed'
    }
}

export type VisitKey = keyof Visit

export type VisitStateType = 'new' | 'transcribing' | 'processing' | 'completed'
const visitStateRec: Record<VisitStateType, string> = {
    new: 'New',
    transcribing: 'Recording',
    processing: 'Processing',
    completed: 'Completed',
}
const visitStateDescRec: Record<VisitStateType, string> = {
    new: 'Start recording to begin your visit',
    transcribing: 'For richer notes, consider recording a bit longer',
    processing: 'Processing your visit',
    completed: 'Visit completed',
}

export class VisitsGroup {
    type!: VisitsGroupType
    visitsCount!: number
    visitsIds!: string[]

    static filterByGroup(visits: Visit[]): VisitsGroup[] {
        const today = moment().startOf('day')
        const yesterday = moment().subtract(1, 'days').startOf('day')

        const visitsGroupsMap = visits.reduce((map, visit) => {
            let type = ''

            if (moment(visit.createdAt).isSame(today, 'day')) {
                type = 'Today'
            } else if (moment(visit.createdAt).isSame(yesterday, 'day')) {
                type = 'Yesterday'
            } else {
                type = moment(visit.createdAt).format(MOMENT_DATE_FORMAT)
            }

            if (map.get(type)) {
                const visits = map.get(type) || []
                visits.push(visit)
            } else {
                map.set(type, [visit])
            }

            return map
        }, new Map<VisitsGroupType, Visit[]>())

        const visitsGroups: VisitsGroup[] = []
        visitsGroupsMap.forEach((visits, type) => {
            const visitGroup = {
                type,
                visitsCount: visits.length,
                visitsIds: visits.map((i) => i._id),
            }
            visitsGroups.push(visitGroup)
        })

        return visitsGroups
    }
}

type VisitsGroupType = 'Today' | 'Yesterday' | string

export class Transcript {
    content!: string
    language?: string
    timestamp!: Date
    translation?: TranscriptTranslation

    static deserialize(obj: any): Transcript {
        const model: Transcript = Object.assign({}, obj)
        model.timestamp = new Date(obj.timestamp)
        return model
    }
}

export class TranscriptPartial extends Transcript {
    isPartial!: boolean
}

export interface TranscriptTranslation {
    languageTarget: string
    languageSource: string
    content: string
}

export type VisitType = 'dictation' | 'visit'