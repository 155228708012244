import classNames from 'classnames'
import { ReactNode, useCallback} from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

interface Props {
    className?: string,
    dictationContent: string,
    childNode(): ReactNode
}

const Styles = styled.div`
`

export const CopyDictation = ({
    className,
    childNode,
    dictationContent
}: Props) => {
    const onClick = useCallback(
        async (event: any) => {
            event.stopPropagation()
            const text = dictationContent

            if (!text) {
                return
            }

            navigator.clipboard.writeText(text)
            toast.success('Copied to clipboard')
        },
        [dictationContent]
    )

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode()}
        </Styles>
    )
}