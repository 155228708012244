import { createSlice } from '@reduxjs/toolkit'

import { Transcript, TranscriptPartial, Visit, VisitKey, VisitsGroup } from '../../services/models/Visit.model'

interface Action {
    type: string
    payload: string | string[] | Visit | Visit[] | VisitPartialUpdate | TranscriptAddition | DurationUpdate | TitleUpdate | DictationAddition
}

interface InitialState {
    visits: Visit[]
    visitsFiltered: Visit[]
    visitsGroups: VisitsGroup[]
    paginationIndex: number
}

type VisitPartialUpdate = {
    _id: string
    key: VisitKey
    value: any
    propagate?: boolean // Reduce re-renders by having less update propagation
}

type DurationUpdate = {
    _id: string
    duration: number
    propagate?: boolean
}

type TitleUpdate = {
    _id: string
    title: string
    propagate?: boolean
}

type TranscriptAddition = {
    _id: string
    transcript: Transcript | TranscriptPartial
}
type DictationAddition = {
    _id: string
    dictation: string
}

const initialState: InitialState = {
    visits: [],
    visitsFiltered: [],
    visitsGroups: [],
    paginationIndex: 0
}

const visitsSlice = createSlice({
    initialState,
    name: 'visits',
    reducers: {
        addAllVisitsReducer(state, { payload }: Action) {
            const newVisits = payload as Visit[]
            // Check if new visits are already in the state
            const visitsIds = state.visits.map((i) => i._id)
            const visitsToAdd = newVisits.filter((i) => !visitsIds.includes(i._id))

            const visits = [...state.visits, ...visitsToAdd]
            const paginationIndex = state.paginationIndex + visitsToAdd.length

            // Update state
            state.visits = visits
            state.visitsFiltered = visits
            state.visitsGroups = VisitsGroup.filterByGroup(visits)
            state.paginationIndex = paginationIndex
        },
        addVisitReducer(state, { payload }: Action) {
            const newVisit = payload as Visit

            // Check if new visit is already in the state
            const visitsIds = state.visits.map((i) => i._id)
            if (visitsIds.includes(newVisit._id)) {
                return state
            }

            const visits = [newVisit, ...state.visits]

            // Update State
            state.visits = visits
            state.visitsFiltered = visits
            state.visitsGroups = VisitsGroup.filterByGroup(visits)
        },
        updateVisitReducer(state, { payload }: Action) {
            const visitPartialUpdate = payload as VisitPartialUpdate
            const propagate = visitPartialUpdate.propagate

            const visits = [...state.visits].map((i) => {
                if (i._id === visitPartialUpdate._id) {
                    return {
                        ...i,
                        [visitPartialUpdate.key]: visitPartialUpdate.value
                    }
                }
                return i
            })

            let visitsGroups = state.visitsGroups
            if (propagate) {
                visitsGroups = VisitsGroup.filterByGroup(visits)
            }

            // Update State
            state.visits = visits
            state.visitsFiltered = visits
            state.visitsGroups = visitsGroups
        },
        addTranscriptReducer(state, { payload }: Action) {
            const id = (payload as TranscriptAddition)._id
            const transcript = (payload as TranscriptAddition).transcript

            const visits = [...state.visits].map((i): Visit => {
                if (i._id === id) {
                    const transcripts = [...i.transcripts].filter((i) => !(i as TranscriptPartial).isPartial)
                    transcripts.push(transcript)

                    return {
                        ...i,
                        transcripts,
                        state: 'transcribing'
                    }
                }
                return i
            })

            // Update State
            state.visits = visits
            state.visitsFiltered = visits
        },
        updateDictationReducer(state, { payload }: Action) {
            const id = (payload as DictationAddition)._id
            const dictation = (payload as DictationAddition).dictation

            const visits = [...state.visits].map((i): Visit => {
                if (i._id === id) {
                    return {
                        ...i,
                        dictation: dictation
                    }
                }
                return i
            })

            // Update State
            state.visits = visits
            state.visitsFiltered = visits
        },
        updateDurationReducer(state, { payload }: Action) {
            const id = (payload as DurationUpdate)._id
            const duration = (payload as DurationUpdate).duration
            const propagate = (payload as DurationUpdate).propagate

            const visits = [...state.visits].map((i): Visit => {
                if (i._id === id) {
                    return {
                        ...i,
                        duration
                    }
                }
                return i
            })

            let visitsGroups = state.visitsGroups
            if (propagate) {
                visitsGroups = VisitsGroup.filterByGroup(visits)
            }

            // Update State
            state.visits = visits
            state.visitsFiltered = visits
            state.visitsGroups = visitsGroups
        },
        updateTitleReducer(state, { payload }: Action) {
            const id = (payload as TitleUpdate)._id
            const title = (payload as TitleUpdate).title
            const propagate = (payload as TitleUpdate).propagate

            const visits = [...state.visits].map((i): Visit => {
                if (i._id === id) {
                    return {
                        ...i,
                        title
                    }
                }
                return i
            })

            let visitsGroups = state.visitsGroups
            if (propagate) {
                visitsGroups = VisitsGroup.filterByGroup(visits)
            }

            // Update State
            state.visits = visits
            state.visitsFiltered = visits
            state.visitsGroups = visitsGroups
        },
        deleteVisitReducer(state, { payload }: Action) {
            const id = payload as string
            const visits = [...state.visits].filter((i) => i._id !== id)

            // Update State
            state.visits = visits
            state.visitsFiltered = visits
            state.visitsGroups = VisitsGroup.filterByGroup(visits)
        },
        deleteVisitsReducer(state, { payload }: Action) {
            const ids = payload as string[]
            const visits = [...state.visits].filter((i) => !ids.includes(i._id))

            // Update State
            state.visits = visits
            state.visitsFiltered = visits
            state.visitsGroups = VisitsGroup.filterByGroup(visits)
        },
        searchVisitReducer(state, { payload }: Action) {
            const searchTerm = payload as string
            const visitsFiltered = Visit.filterByTitle(searchTerm, state.visits)

            // Update State
            state.visitsFiltered = visitsFiltered
            state.visitsGroups = VisitsGroup.filterByGroup(visitsFiltered)
        }
    }
})

export const {
    addAllVisitsReducer,
    addVisitReducer,
    addTranscriptReducer,
    deleteVisitReducer,
    deleteVisitsReducer,
    searchVisitReducer,
    updateTitleReducer,
    updateDurationReducer,
    updateDictationReducer,
    updateVisitReducer
} = visitsSlice.actions
export default visitsSlice.reducer
