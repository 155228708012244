import Quill from 'quill'
import { MutableRefObject, useCallback, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'

import { DictationTranscriber, DictationTranscriberEventData } from '../../../lib/DictationTranscriber'
import { useLocalContext } from './useLocalContext'

interface Props {
    quillRef: MutableRefObject<ReactQuill | null>
}

const dictionary: { [key: string]: string } = {
    'full stop': 'fullstop',
    'new line': 'newline',
    'new paragraph': 'newparagraph'
}

export const useQuillEditor = ({ quillRef }: Props) => {
    const { recorder } = useLocalContext()
    const [cursorStartPosition, setCursorStartPosition] = useState<number>(0)
    const [cursorEndPosition, setCursorEndPosition] = useState<number>(0)
    const [firstText, setFirstText] = useState(true)
    const [manager, setManager] = useState<QuillEditorManager | null>(null)

    useEffect(() => {
        // Set Editor manager
        if (!quillRef.current) return
        const quillManager = new QuillEditorManager(quillRef.current)
        setManager(quillManager)
    }, [quillRef])

    
    const processText = useCallback(
        (transcribedText: string) => {
            // Construct a dynamic regex pattern from the dictionary keys
            const pattern = new RegExp(Object.keys(dictionary).join('|'), 'gi')
            const replacedStr = transcribedText.replace(pattern, function (matched) {
                return dictionary[matched.toLowerCase() as keyof typeof dictionary] || matched
            })
            const transcribedArr = replacedStr.split(' ')

            transcribedArr.forEach((word) => {
                const normalizedWord = word
                    .trim()
                    .toLowerCase()
                    .replace(/\.|,|!|@|#|$|^|\*/g, '')
                if (normalizedWord === 'newline') {
                    manager?.newLine()
                } else if (normalizedWord === 'newparagraph') {
                    manager?.newPara()
                } else if (normalizedWord === 'fullstop') {
                    manager?.addPeriod()
                } else if (normalizedWord === 'tab') {
                    manager?.tab()
                } else if (normalizedWord === 'dash') {
                    manager?.dash()
                } else if (normalizedWord === 'slash') {
                    manager?.slash()
                } else {
                    manager?.addText(`${word.trim()} `)
                }
            })
        },
        [manager]
    )

    useEffect(() => {
        const transcriber = recorder.transcriber as DictationTranscriber | null
        if (!transcriber || !manager) {
            setFirstText(true)
            return
        }

        const subscription = transcriber?.getObservable().subscribe((event: DictationTranscriberEventData) => {
            switch (event.action) {
                case 'partialContent': {
                    // Set cursor position if Sate(firstText)
                    if (firstText) setCursorStartPosition(manager.cursorPosition())

                    // if !FirstText : Remove text from cursorStartPosition to cursorEndPosition
                    if (!firstText) manager.removeText(cursorStartPosition, cursorEndPosition)

                    // Add text from cursor position
                    const transcribedText = event.payload as string
                    processText(transcribedText)

                    // Set firstText to false
                    setFirstText(false)
                    // Set End edit pos
                    setCursorEndPosition(manager.cursorPosition())

                    break
                }
                case 'content': {
                    // Remove text from cursor position to end of edit
                    manager.removeText(cursorStartPosition, cursorEndPosition)

                    // Add text
                    const transcribedText = event.payload as string
                    processText(transcribedText)

                    // Set firstText to true
                    setFirstText(true)
                    break
                }
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [recorder.transcriber, processText, cursorStartPosition, manager, firstText, cursorEndPosition])
    return null
}

class QuillEditorManager {
    private editor: Quill

    constructor(quillRef: ReactQuill) {
        this.editor = quillRef.getEditor()
    }

    addText(text: string) {
        if (!this.editor) return
        let range = this.editor.getSelection() // Get the current cursor position
        if (!range) {
            this.editor.focus()
            range = this.editor.getSelection() // Try to get the selection again after focus
        }

        if (range) {
            this.editor.insertText(range.index, text) // Insert the text at the current cursor position
            this.editor.setSelection({ index: range.index + text.length, length: 0 }) // Move the cursor to the end of the inserted text
        } else {
            // If no selection, insert at the end
            const length = this.editor.getLength()
            this.editor.insertText(length, text) // Insert at the end
            this.editor.setSelection(this.editor.getLength()) // Move the cursor to the end
        }
    }
    removeText(cursorStartIndex: number, cursorEndIndex: number) {
        if (!this.editor) return
        const lengthToRemove = cursorEndIndex - cursorStartIndex
        if (lengthToRemove > 0) {
            // Remove text from the end to the cursor position
            this.editor.deleteText(cursorStartIndex, lengthToRemove)
        }
    }
    addPeriod() {
        // Get the current selection range
        let range = this.editor.getSelection()
        if (!range) {
            this.editor.focus()
            range = this.editor.getSelection() // Try to get the selection again after focusing
        }

        if (range) {
            const cursorIndex = range.index // Current cursor position

            // Get the text before the cursor position
            const textBeforeCursor = this.editor.getText(cursorIndex - 1, 1)

            if (textBeforeCursor === ' ') {
                // Replace the trailing space with a period
                this.editor.deleteText(cursorIndex - 1, 1) // Remove the space
                this.editor.insertText(cursorIndex - 1, '.') // Insert the period
            } else if (textBeforeCursor !== '.') {
                // If there's no trailing space or period, just add the period
                this.editor.insertText(cursorIndex, '.')
            }

            // Move the cursor to the end of the inserted period
            this.editor.setSelection(cursorIndex + 1, 0)
            this.addText(' ')
        }
    }
    newLine(addPeriod:boolean = true) {
        if (!this.editor) return
        // Get the current selection or cursor position
        let range = this.editor.getSelection()
        if (!range) {
            this.editor.focus()
            range = this.editor.getSelection() // Try to get the selection again after focusing
        }

        if (range) {
            // Insert a new line at the current cursor position
            if(addPeriod){
                this.addPeriod()
            }
            this.editor.insertText(range.index, '\n') // Insert line break
            const cursorIndex = range.index + 2
            this.editor.setSelection(range.index + 2, 0) // Move the cursor to the new line

            const textBeforeCursor = this.editor.getText(cursorIndex - 1, 1)

            if (textBeforeCursor === ' ') {
                // Replace the trailing space with a period
                this.editor.deleteText(cursorIndex - 1, 1) // Remove the space
            }
        }
    }
    newPara() {
        if (!this.editor) return
        // Get the current selection or cursor position
        this.newLine(false)
        this.newLine(false)
    }

    tab() {
        this.addText('    ')
    }
    dash() {
        this.addText(' - ')
    }
    slash() {
        this.addText('/')
    }

    cursorPosition() {
        const range = this.editor?.getSelection()
        if (!range) return this.editor.getLength()
        return range.index
    }

    cursorEndPosition() {
        if (!this.editor) return 0
        return this.editor.getLength()
    }

    disableEditor() {
        this.editor.disable()
    }
}
